import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { UsersService } from '../services/users.service';
import { DecodeTokenService } from '../services/decode-token.service';
declare var $:any;
@Component({
  selector: 'app-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.css']
})
export class ProfileNavComponent implements OnInit {

  user:any;
  notificationsArray: any[];

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private decodeTokenService: DecodeTokenService
    ) { }

  ngOnInit(): void {
    var userId = this.decodeTokenService.decodeUserId();
    this.usersService.getUser(userId)
    .subscribe(user => this.generateName(user));
    this.testToken()
  }

  testToken(){
    var exp = new Date(this.decodeTokenService.decodeExp());
    var expe = exp.getTime();
    var time = new Date();
    var now = time.getTime()/1000;
    var remaining = expe - now;
    if (remaining < 0) {
      this.logout();
    }
    
  }

  generateName(user){
    document.getElementById("userAnzeige").innerHTML = user.firstName + ' ' + user.lastName ;
  }

  clearLocalStorage(){
    try {
      this.updatePosition(null);
    } catch (error) {
      
    }
    localStorage.clear();
    this.logout();
  }

  logout(){
    try {
      this.updatePosition(null);
    } catch (error) {
      
    }
    this.authService.logout();
    
  }

  fadeuserAnzeige(){
    let element = document.getElementById('userAnzeige');
    element.style.right = '3.3em';
    element.style.opacity = '100%';
  }
  
  fadeOut(){
    let element2 = document.getElementById('userAnzeige');
    element2.style.right = '-10em';
    element2.style.opacity = '0%';
  }

  updatePosition(posId){
    this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId});
  }
/*Array in LocalStorage speichern
  var names = [];
  names[0] = prompt("New member name?");
  localStorage.setItem("names", JSON.stringify(names));
  
  //...
  var storedNames = JSON.parse(localStorage.getItem("names"));
  */
}
