import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
declare var $;

@Component({
  selector: 'app-registrierung',
  templateUrl: './registrierung.component.html',
  styleUrls: ['./registrierung.component.css']
})
export class RegistrierungComponent implements OnInit {
  email: string;
  password: string;
  passwordConfirm: string;
  show = false;
  fN: string;
  lN: string;
  db: any;
  constructor(
    private usersService: UsersService
  ){}

    ngOnInit() {
       this.usersService.getUsers()
       .subscribe(db => this.db = db);

       const el = document.querySelector("body");

        el.addEventListener("mousemove", (e) => {
          el.style.backgroundPositionX = -e.offsetX + "px";
          el.style.backgroundPositionY = -e.offsetY + "px";
        });
    }

    updateEmail(email){
      this.email = email.toLowerCase();
      document.getElementById("alert").style.display = "none";
    }

    updatePw(pw){
      this.password = pw;
      document.getElementById("alert").style.display = "none";
    }

    updateFn(fN){
      this.fN = fN;
      document.getElementById("alert").style.display = "none";
    }

    updateLn(lN){
      this.lN = lN;
      document.getElementById("alert").style.display = "none";
    }

    updatePwConf(pw){
      this.passwordConfirm = pw;
      document.getElementById("alert").style.display = "none";
    }


    onSubmit(){
      var element3 = <HTMLInputElement> document.getElementById("defaultCheck1");
      var checked = element3.checked;
      try {
        var at = this.email.includes("@"); 
        var dot = this.email.includes("."); 
      } catch (error) {}
      
      if (this.fN == '' || this.lN == '' || this.email == '' || this.password == '' || this.fN == null || this.lN == null || this.email == null || this.password == null || checked == false) {
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").innerHTML = "Bitte füllen Sie alle Felder aus und akzeptieren Sie unsere AGBs und Datenschutzerklärung.";
      }else if(at == false || dot == false){
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").innerHTML = "Bitte geben Sie eine gültige Emailadresse an.";
      }else if(this.password != this.passwordConfirm){
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").innerHTML = "Die beiden Passwörter stimmen nicht überein.";
      }else if(this.password.length < 8){
        document.getElementById("alert").style.display = "block";
        document.getElementById("alert").innerHTML = "Bitte wählen Sie ein längeres Passwort.";
      }else{
        for (let index = 0; index < this.db.length; index++) {
          if (this.db[index].eMail == this.email) {
            document.getElementById("alert").style.display = "block";
            document.getElementById("alert").innerHTML = "Die eingegebene Emailadresse ist bereits bei uns registriert.";
            return false;
          }
        }
            //erfolgreich registrieren
            this.usersService.register(this.fN, this.lN, this.email, this.password);
            document.getElementById("success").style.display = "block";
            document.getElementById("formular").style.display = "none";
            this.usersService.sendMail();
          
          
        }
        
      }

      
    

  

}
