<div>
    <h3>Fragen</h3>

    <p>Klicken Sie auf einen Raum, um die Fragen zu sehen.</p>
    
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Beschreibung</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let chatroom of chatrooms" class="mouse-pointer" routerLink="/admin/question/view/{{chatroom.id}}">
                <th scope="row">{{chatroom.id}}</th>
                <td>{{chatroom.name}}</td>
                <td>{{chatroom.description}}</td>
            </tr>
        </tbody>
    </table>
</div>