
<body>


    <nav class="navbar sticky-top navbar-expand-lg navbar-light" id="filterBar">
        <a class="navbar-brand">MEDIATHEK</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <input class="form-control" type="search" placeholder="Suchen..." aria-label="Search" [(ngModel)]="mediaSuche">
          <div class="navbar-nav">
            <a class="nav-item nav-link active unselectable pointer" (click)="filtern('')">Alle <span class="sr-only">(current)</span></a>
            <a class="nav-item nav-link unselectable pointer" (click)="filtern('mp4')">Videos</a>
            <a class="nav-item nav-link unselectable pointer" (click)="filtern('mp3')">Ton</a>
            <a class="nav-item nav-link unselectable pointer" (click)="filtern('poster')">Dokumente</a>
          </div>
            
        </div>
      </nav>
    

      <div class="featuredContainer" *ngFor="let feature of featured |filter:mediaSuche">
        <div *ngIf="feature.mediatype.id ===1 && feature.featured"><!--Image-->
            <div class="container unselectable" id="{{feature.url}}" (click)="open(feature.url, feature.id)">
                <div class="media">
                    <img src="{{feature.url}}" style="height: 8em; width: 20%; object-fit: cover;" class="mr-3" id="{{feature.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{feature.name}}</h5>
                        <p>{{feature.description}}</p>
                        <!--<button class="btn btn-outline-dark">Bild vergrößern</button>-->
                        <p class="createdAt">FEATURED</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="feature.mediatype.id ===4 && feature.featured"><!--MP4-->
            <div class="container unselectable" (click)="open(feature.url, feature.id)">
                <div class="media">
                    <video src="{{feature.url}}" style="height: 8em; width: 20%; object-fit: contain;" class="mr-3 {{feature.id}}" id="{{feature.id}}" preload="auto"></video>
                    <div class="media-body">
                        <h5 class="mt-0">{{feature.name}}</h5>
                        <p>{{feature.description}}</p>
                        <!--<button class="btn btn-outline-dark">Video abspielen</button>-->
                        <p class="createdAt">FEATURED</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="feature.mediatype.id ===2 && feature.featured"><!--PDF-->
            <a class="dokumentLink" href="{{feature.url}}" target="blank">
                <div class="container unselectable">
                    <div class="media">
                        <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{feature.id}}" />
                        <div class="media-body">
                            <h5 class="mt-0">{{feature.name}}</h5>
                            <p>{{feature.description}}</p>
                            <button class="btn btn-outline-dark">Dokument öffnen</button>
                            <p class="createdAt">FEATURED</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>


<div class="mediencenterContainer" *ngFor="let media of medien |filter:mediaSuche">
    <div *ngIf="media.mediatype.id ===1 && media.mediacenter"><!--Image-->
        <div class="container unselectable" id="{{media.url}}" (click)="open(media.url, media.id)">
            <div class="media">
                <img src="{{media.url}}" style="height: 8em; width: 20%; object-fit: cover;" class="mr-3" id="{{media.id}}" />
                <div class="media-body">
                    <h5 class="mt-0">{{media.name}}</h5>
                    <p>{{media.description}}</p>
                    <!--<button class="btn btn-outline-dark">Bild vergrößern</button>-->
                    <p *ngIf="media.created" class="createdAt">Hochgeladen: {{media.created | date: 'd.M.y' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="media.mediatype.id ===4 && media.mediacenter"><!--MP4-->
        <div class="container unselectable pointer" (click)="open(media.url, media.id)">
            <div class="media">
                <video src="{{media.url}}" style="height: 8em; width: 20%; object-fit: contain;" class="mr-3 {{media.id}}" id="{{media.id}}" controls preload="metadata"></video>
                <div class="media-body">
                    <h5 class="mt-0">{{media.name}}</h5>
                    <p>{{media.description}}</p>
                    <!--<button class="btn btn-outline-dark">Video abspielen</button>-->
                    <p *ngIf="media.created" class="createdAt">Hochgeladen: {{media.created | date: 'd.M.y' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="media.mediatype.id ===7 && media.mediacenter"><!--MP3-->
        <div class="container unselectable pointer" (click)="open(media.url, media.id)">
            <div class="media">
                <audio src="{{media.url}}" style="max-height: 8em; max-width: 20%; object-fit: contain;" class="mr-3 {{media.id}}" id="{{media.id}}" controls preload="metadata" controlsList="nodownload"></audio>
                <div class="media-body">
                    <h5 class="mt-0">{{media.name}}</h5>
                    <p>{{media.description}}</p>
                    <!--<button class="btn btn-outline-dark">Video abspielen</button>-->
                    <p *ngIf="media.created" class="createdAt">Hochgeladen: {{media.created | date: 'd.M.y' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="media.mediatype.id ===2 && media.mediacenter"><!--PDF-->
        <a class="dokumentLink" (click)="openModal(media.url)" target="blank">
            <div class="container unselectable pointer">
                <div class="media">
                    <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{media.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{media.name}}</h5>
                        <p>{{media.description}}</p>
                        <button class="btn btn-outline-dark">Dokument öffnen</button>
                        <p *ngIf="media.created" class="createdAt">Hochgeladen: {{media.created | date: 'd.M.y' }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div *ngIf="media.mediatype.id ===10 && media.mediacenter"><!--PDF-->
        <a class="dokumentLink" (click)="openModal2(media.url)" target="blank">
            <div class="container unselectable">
                <div class="media">
                    <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{media.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{media.name}}</h5>
                        <p>{{media.description}}</p>
                        <button class="btn btn-outline-dark">Dokument öffnen</button>
                        <p *ngIf="media.created" class="createdAt">Hochgeladen: {{media.created | date: 'd.M.y' }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>
  
<!--Platzhalter unter Medien-->
<div id="containerunten">
</div>

<!-- The Modal -->
<div id="id01" class="w3-modal">
    <div class="w3-modal-content">
      <div class="w3-container">
        <span onclick="document.getElementById('id01').style.display='none'"
        class="w3-button w3-display-topright">&times;</span>
        <br><br>
        <app-pdf-viewer [pdfSource]="url"></app-pdf-viewer>
      </div>
    </div>
  </div>


  <!-- The Modal -->
<div id="id02" class="w3-modal">
    <div class="w3-modal-content">
      <div class="w3-container">
        <span onclick="document.getElementById('id02').style.display='none'"
        class="w3-button w3-display-topright">&times;</span>
        <br><br>
        <img src="{{imgUrl}}" style="max-width: 100%;" />
      </div>
    </div>
  </div>


</body>

<!--Navigation und Agenda einbinden-->
<app-navigation></app-navigation>
