<app-cityhall-nav></app-cityhall-nav>
<!-- <app-cityhall-voting></app-cityhall-voting> -->
<app-agenda></app-agenda>
<app-profile-nav></app-profile-nav>
<app-chat></app-chat>
<body>
   <iframe id="livestream" src="https://www.welocal.world/cmms-embed/1994/" frameborder="0" allowfullscreen></iframe> 
  <!--<video id="video" width="100%" height="auto" autoplay muted>
    <source src="assets/video/trailer.mp4" type="video/mp4" />
  </video>-->


          
<!--
<div class="Ankuendigung">
  Hier findet am Donnerstag ein Livestream statt.
</div>
      
-->


 


   


    <!--<button type="button" class="WhiteboardBtn" id="whiteboardToggle" data-toggle="modal" data-target=".bd-example-modal-lg">Whiteboard</button>-->

</body>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">


        <div id="whiteboard">
          <iframe src="https://wbo.ophir.dev/boards/wesdemowhiteboardcityhalltestumgebungnichtfinal#0,0,1.0" style="border:0px #ffffff none;" name="whiteboardFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="100%" width="100%" allowfullscreen>
          </iframe>
         </div>


    </div>
  </div>
</div>

<button class="toprightBtn" id="cityhallAgenda" (click)="timetableEinblenden()"><img src="assets/images/agenda-white.png" title="Detailprogramm" /></button>

<div class="countdownAgenda" id="timetableNeu">
  <p id="closeCityhallAgenda" (click)="timetableEinblenden()">X</p>
  <h2>Detailprogramm:</h2>
  <ul>
    <li>9:00 - 10:00 Uhr: Eröffnung und Begrüßung, Moderation: Prof. Dr. Kay-Uwe Götz (LfL)</li><ul>
      <li>Dr. Eric Veulliet
        Präsident der Hochschule Weihenstephan-Triesdorf (HSWT)</li>
      <li>Jakob Opperer
        Präsident der Bayerischen Landesanstalt für Landwirtschaft (LfL)</li>
        <li>Hubert Heigl
          Erster Vorsitzender der Landesvereinigung für ökologischen Landbau in Bayern (LVÖ)</li>
          <li>Keyword: Bernhard Schreyer - Betriebsporträt und Visionen für die Praxis-Forschung
            Schloss Gut Obbach, Sieger beim Bundespreis Ökologischer Landbau 2020</li>
    </ul>

    <li>10:00 – 12:00 Uhr: Vortragssektionen am Vormittag (Schweine/Geflügel/Teichwirtschaft), Moderation: Prof. Dr. Wilhelm Pflanz und Prof. Dr. Gerhard Bellof (HSWT)</li><ul>
      <li>•	Ferkelkastration unter Narkose – Praxistauglichkeit und Verbesserungsansätze
        Dr. Werner Hagmüller, HBLFA Raumberg-Gumpenstein, Institut für biologische Landwirtschaft und Biodiversität der Nutztiere, Außenstelle Thalheim/Wels
        </li>
        <li>•	Futteraufnahmeverhalten von Sauen und Ferkeln in freien Abferkelsystemen
          Paul Schwediauer, HBLFA Raumberg-Gumpenstein, Institut für biologische Landwirtschaft und Biodiversität der Nutztiere, Außenstelle Thalheim/Wels
          </li>
          <li>•	Einfluss von Verhaltensmerkmalen und Wurfeigenschaften auf eine erfolgreiche Ferkelerzeugung im ökologischen Landbau
            Elisabeth Sinz, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz
            </li>
            <li>•	Bedarfsgerechte Riboflavin-(Vitamin B2-)Versorgung bei langsam wachsenden Masthühnern
              Dr. Christian Lambertz, Forschungsinstitut für biologischen Landbau
              </li>
              <li>•	Untersuchungen zur Riboflavinversorgung in der ökologischen Legehennenfütterung
                Peter Weindl, Hochschule Weihenstephan-Triesdorf, Fakultät Nachhaltige Agrar- und Energiesysteme
                </li>
                <li>•	Untersuchungen zur Riboflavinversorgung in der ökologischen Aufzucht schwerer Putenherkünfte
                  Sina Göppel, Hochschule Weihenstephan-Triesdorf, Fakultät Nachhaltige Agrar- und Energiesysteme, Fachgebiet Tierernährung
                  </li>
                  <li>•	Einfluss von gemälztem Weizen auf die zootechnische Leistung von Öko-Mastgeflügel
                    Sylvia Kuenz, Technische Universität München, Lehrstuhl für Tierernährung
                    </li>
                    <li>•	Organische Düngung in der Karpfenteichwirtschaft: Fischertrag und Umweltaspekte
                      Dr. Jan Másílko, Bayerische Landesanstalt für Landwirtschaft, Institut für Fischerei, Außenstelle für Karpfenteichwirtschaft
                      </li>
    </ul>
    <li>12:00 - 13:30 Uhr: Mittagspause</li>
    <li>14:45 – 15:45 Uhr: Vortragssektionen am Nachmittag mit Schlusswort (Sozioökonomie/Märkte), Moderation: HSWT</li><ul>
      <li>•	Potenziale von Wertschöpfungsketten für Spezialprodukte des Ökolandbaus in Bayern
        Prof. Dr. Paul Michels, Anna-Lena Manz, Hochschule Weihenstephan-Triesdorf
        </li>
        <li>•	Qualitative Analyse von Erfolgs- und Misserfolgsfaktoren in den sechs südlichen Öko-Modellregionen Bayerns
          Theresa Hautzinger, Technische Universität München
          </li>
          <li>•	Der LfL-Umstellungsplaner Kon-2-Öko
            Robert Schätzl, Bayerische Landesanstalt für Landwirtschaft, Institut für Betriebswirtschaft und Agrarstruktur
            </li>
    </ul>
    <li>Schlusswort von LfL-Präsident Jakob Opperer</li>
  </ul>
  <div id="nextAgenda"></div>
</div>