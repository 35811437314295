<body>

    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
</ul>
    <br><img class="LogoEinloggen" src="assets/images/wew_cropped.png" id="bildOben"/><br>
    <div class="loginForm">
        <h2>Registrieren</h2>
        <form (submit)="onSubmit()" id="formular">
            <label for="firstName" id="firstNamel">Vorname</label>
            <input type="text" class="form-control" title="Vorname" id="firstName" placeholder="Vorname" #firstName (focusout)="updateFn(firstName.value)" autocomplete="new-password" required>
            <br>
            <label for="lastName" id="lastNamel">Nachname</label>
            <input type="text" class="form-control" title="Nachname" id="lastName" placeholder="Nachname" #lastName (focusout)="updateLn(lastName.value)" autocomplete="new-password" required>
            <br id="5">
            <label for="email" id="emaill">E-Mail Adresse</label>
            <input type="email" class="form-control" title="E-Mail" id="email" placeholder="E-Mail" #email (focusout)="updateEmail(email.value)" autocomplete="new-password" required>
            <br id="4">
            <label for="password" id="passwordl">Passwort</label>
            <input type="password" class="form-control" title="Passwort" id="password" placeholder="Passwort" #password (focusout)="updatePw(password.value)" autocomplete="new-password" required>
            <small id="pwHelp" class="form-text text-muted">Wählen Sie ein sicheres Passwort mit mindestens 8 Stellen, Zahlen und Sonderzeichen.</small>
            <br id="3">
            <label for="passwordConfirm" id="passwordcl">Passwort bestätigen</label>
            <input type="password" class="form-control" title="Passwort bestätigen" id="passwordConfirm" placeholder="Passwort bestätigen" #passwordConfirm (focusout)="updatePwConf(passwordConfirm.value)" autocomplete="new-password" required>
            <br>
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" style="margin-left: 0em;">
            <label class="form-check-label" for="defaultCheck1" style="margin-left: 1em;" id="defaultCheck1l">
              Ich akzeptiere die AGB und die <a href="https://webeventstudios.com/page-4/" target="blank">Datenschutzerklärung</a> von webeventstudios.com
            </label>
            <br id="2">
            <br id="1">
            <div class="alert alert-danger" role="alert" id="alert" style="display: none;"></div>
            <button type="submit" class="btn btn-primary">Registrieren</button>
        </form>
        <div class="alert alert-success" role="success" id="success" style="display: none;">Ihr Benutzerkonto wurde erfolgreich angelegt! Sie können sich jetzt <a href="login">hier</a> einloggen.</div>
    </div>
    <br><img class="LogoEinloggen" src="assets/images/wew_cropped.png" id="bildUnten"/><br>


    <div class="btn-group impressBtn" role="group" aria-label="Basic example">
        <a href="https://webeventstudios.com/page-3/" target="blank" type="button" class="btn btn-outline-light">Impressum</a>
        <a href="https://webeventstudios.com/page-4/" target="blank" type="button" class="btn btn-outline-light">Datenschutz</a>
      </div>

</body>