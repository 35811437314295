<!--<h2>Public Chat</h2>-->



<div id="nachrichten">

    <div id="bestaetigung" style="display: none;">
        Ihre Frage wurde erfolgreich gesendet!<br><a (click)="returnToQuestions()">Noch eine Frage stellen...</a>
    </div>

    <div id="inputs">
        <div class="input-group mb-3">
            <input type="text" class="form-control" id="eingabefeld" placeholder="Nachricht..." aria-label="Stellen Sie hier Ihre Frage..." aria-describedby="basic-addon2" #chatmessageContent>
            <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" (click)="sendMessage(chatmessageContent.value); chatmessageContent.value=''">Senden</button>
            </div>
        </div>
    </div>

    
</div>