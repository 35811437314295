<body>
  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
</ul>

  <div class="interessen">
      <h3>Interessen</h3>
      <p>Wählen Sie bitte Ihre Interessen aus:</p>
      <a class="dropdown-item" *ngFor ="let filter of branches">
                  

          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input custom-checkbox" id={{filter.id}} (click)="filterAnwenden(filter.id)">
            <label class="custom-control-label unselectable" for={{filter.id}}>{{filter.name}}</label>
          </div>
        </a>

          <button class="btn btn-primary" (click)="filterAnwenden('alle')">Alle auswählen</button>
          <a class="btn btn-primary" href="welcomecenter">Weiter</a>

  </div>

</body>
