import { Component, OnChanges, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { Chatroom } from '../models/chatroom';
import { Chatmessage } from '../models/chatmessage';
import {BenachrichtigungService} from '../services/benachrichtigung.service'
import { DecodeTokenService } from '../services/decode-token.service';
import { SharedService } from '../services/shared.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  @Output() messageToEmit = new EventEmitter<string>();
  @Input() chatroom: number;
  userId: any;
  public chatmessages = new Array();
  sendNotifications: boolean = false;

  constructor( 
    private chatService: ChatService, 
    private benachrichtigungService: BenachrichtigungService, 
    private decodeTokenService: DecodeTokenService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.userId = this.decodeTokenService.decodeUserId().toString();
    console.log(this.userId);
    const eingabe = document.getElementById("eingabefeld") as HTMLInputElement;
    eingabe.addEventListener('keydown', (e)=>{if (13 == e.keyCode) {
        this.sendMessage(eingabe.value);
        eingabe.value = '';
     }});
     if(this.router.url === '/cityhall'){
       this.chatroom = 1;
     }else if(this.router.url === '/cityhall2'){
       this.chatroom = 2;
     }
    }

  ngOnChanges(): void {
    this.chatmessages = [];
    this.joinRoom();
  }

  joinRoom() {
    this.chatService.joinRoom(this.chatroom);
  }


  sendMessage(content) {
    if(content != ''){
      //Chatroom Id
      const id = this.chatroom;
      const chatroom = {id} as Chatroom;

      const chatmessage = {content, chatroom} as Chatmessage;
      
      this.chatService.sendPublicmessage(chatmessage);
      this.successSeding();
    }
  }

  testton(url):void{
    new Audio(url).play();
  }

  returnToQuestions(){
    const input = document.getElementById("inputs");
    const bestaetigung = document.getElementById("bestaetigung");
    input.style.display = "block";
    bestaetigung.style.display = "none";
  }

  successSeding(){
      const input = document.getElementById("inputs");
      const bestaetigung = document.getElementById("bestaetigung");
      input.style.display = "none";
      bestaetigung.style.display = "block";
  }
}
