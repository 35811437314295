import { Component, OnInit } from '@angular/core';
import { Chatroom } from '../../_models/chatroom';
import { ChatroomService } from '../../_services/chatroom.service';

@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css']
})
export class QuestionListComponent implements OnInit {

  chatrooms: Chatroom[] = new Array();

  constructor(
    private chatroomService: ChatroomService
  ) { }

  ngOnInit(): void {
    this.getChatrooms();
  }

  getChatrooms(): void {
    this.chatroomService.getChatroom(101).subscribe(chatroom =>
      this.chatrooms.push(chatroom)
    );
    this.chatroomService.getChatroom(102).subscribe(chatroom =>
      this.chatrooms.push(chatroom)
    );
  }
}
