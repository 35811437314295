import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GatewayService } from 'src/app/services/gateway.service';
import { Chatmessage } from '../../../models/chatmessage';
// import { Chatmessage } from '../../_models/chatmessage';

@Component({
  selector: 'app-question-view',
  templateUrl: './question-view.component.html',
  styleUrls: ['./question-view.component.css']
})
export class QuestionViewComponent implements OnInit {

  questions: Chatmessage[][];
  chatroomId: number;

  newQuestion: Chatmessage;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.chatroomId = +this.route.snapshot.paramMap.get('id');
  }
}
