import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Company } from '../../_models/company';
import { Role } from '../../_models/role';
import { User } from '../../_models/user';
import { CompanyService } from '../../_services/company.service';
import { RoleService } from '../../_services/role.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  users: User[];
  roles: Role[];
  companies: Company[];
  
  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getUsers();
    this.getRoles();
    this.getCompanies();
  }

  getUsers(): void {
    this.userService.getUsers().subscribe(users =>
      this.users = users
    );
  }

  getRoles(): void {
    this.roleService.getRoles().subscribe(roles =>
      this.roles = roles
    );
  }

  getCompanies(): void {
    this.companyService.getCompanies().subscribe(companies =>
      this.companies = companies
    );
  }
}
