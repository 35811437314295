import { Component, OnInit } from '@angular/core';
import { AgendaService } from '../services/agenda.service';
import { UsersService } from '../services/users.service';
import { DecodeTokenService } from '../services/decode-token.service';
import { InteractionsService } from '../services/interactions.service';

@Component({
  selector: 'app-cityhall2',
  templateUrl: './cityhall2.component.html',
  styleUrls: ['./cityhall2.component.css']
})
export class Cityhall2Component implements OnInit {

  termineCh: any;
  naechsteTermine: any;
  public interactions = new Array();
  public users: number = 0;
  cityhallProgramm: boolean = false;

  constructor(
    private agendaService: AgendaService,
    private usersService: UsersService,
    private decodeTokenService: DecodeTokenService,
    private interactionService: InteractionsService
  ) { }

  ngOnInit(): void {
    this.agendaService.getAgenda()
      .subscribe(termineCh => this.termineCh = termineCh);
      this.agendaService.getAgenda()
      .subscribe(antwort => this.setTimer(antwort));
      // this.updatePosition('5');
      this.interactionService.receiveUsers().subscribe((users: number) => {
        this.users = users;
      });
      this.interactionService.receiveInteraction().subscribe((reaction: string) => {
        this.showInteraction(reaction);
      })
  }

  setTimer(eingabe){
    //document.getElementById('nextAgenda').innerHTML = eingabe;
    console.log(eingabe);
    let location = new Array();
    for (let index = 0; index < eingabe.length; index++) {
      if (eingabe[index].location.id == 20) {
        location.push(eingabe[index]);
      }  
    }
    console.log(location);
    this.naechsteTermine = location;

  }

abstimmen(){
  document.getElementById('abstimmung2').style.display = "none";
}


timetableEinblenden(){
  if (this.cityhallProgramm) {
    document.getElementById('timetableNeu').style.display = "none";
    this.cityhallProgramm = false;
  }else{
    document.getElementById('timetableNeu').style.display = "block";
    this.cityhallProgramm = true;
  }
}

  getRemainingTime(agendaId){
    console.log(this.termineCh);
    var time = new Date();
    var timestamp2 = time.getTime();
    var date = new Date(this.termineCh[agendaId].startTime);
    var timestamp = date.getTime();
    var remainingTimestamp = (timestamp - timestamp2)/1000/86400;
    if (remainingTimestamp*24*60*60 > 86400){
      remainingTimestamp = Math.round(remainingTimestamp);
      return remainingTimestamp + ' Tag(e)';
    }else if (remainingTimestamp < 0){
      return 'vorbei';
    }else if(remainingTimestamp*24*60*60 < 60) {
      remainingTimestamp = Math.round(remainingTimestamp*24*60*60);
      return remainingTimestamp + ' Sekunde(n)';
    }else if(remainingTimestamp*24*60*60 < 3600) {
      remainingTimestamp = Math.round(remainingTimestamp*24*60);
      return remainingTimestamp + ' Minute(n)'
    }else if (remainingTimestamp*24*60*60 < 86400) {
      remainingTimestamp = Math.round(remainingTimestamp*24);
      return remainingTimestamp + ' Stunde(n)'
    }
  }

  testton(url):void{
    var audio = new Audio(url);
    audio.volume = Number(localStorage.getItem('generalVolume'))/100;
    audio.play();
  }

  applaus(){
    this.testton('assets/sounds/applaus1.wav');
  }

  IntState: any;
  toggleInteractions(){
    if (this.IntState == true) {
      //einschalten
    }else{
      //ausschalten
      this.applaus();
    }
  }

  updatePosition(posId){
    this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId});
  }



  //Interactions
 

  addInteraction(reaction: string) {
    this.interactionService.sendInteraction(reaction);
  }
  showInteraction(reaction: string){
    //do something with the interaction events here
    if(reaction == 'applaus'){
      var element = document.getElementById("applausAnimation")
      console.log('Applaus!');
      this.applaus();
      element.classList.add("animation");
      setTimeout(function(){ element.classList.remove('animation'); }, 4000);   
        
      
    }
    this.interactions.push(reaction);
  }
}
