import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Feedback } from '../models/feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  url = `${environment.apiUrl}/feedback`;

  constructor(
    private http: HttpClient
  ) { }

  createFeedbacka(feedback: Feedback): Observable<Feedback>{
    return this.http.post(this.url, feedback)
      .pipe(
        tap(_ => console.log('created feedback id=${feedback.id}')),
        catchError(this.handleError<any>('createMedia'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
