import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MediaService } from '../services/media.service';
import { Media } from '../models/media';
import { Users } from '../models/users';
import { UsersService } from '../services/users.service';
import { DecodeTokenService } from '../services/decode-token.service';



@Component({
  selector: 'app-welcomecenter',
  templateUrl: './welcomecenter.component.html',
  styleUrls: ['./welcomecenter.component.css']
})
export class WelcomecenterComponent implements OnInit {
  media: any;
  videoElement: any;

  constructor(
    private mediaService: MediaService,
    private usersService: UsersService,
    private decodeTokenService: DecodeTokenService) { }
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  ngOnInit(): void {
    this.getMedia(164);
    this.updatePosition('4');
  }

  startevideo(){
    //document.getElementById("video").play();
    
    
    var video = document.querySelector('video');
    video.play();
  }

  //Datenbankanbindung
  getMedia(id): void{
    this.mediaService.getMedia(id)
    .subscribe(media => this.media = media);
  }

  //Umleitung zur Karte;
  //Wird aufgerufen, wenn Video beendet ist (ended)="zurKarte()"
  zurKarte(){
    window.location.href = "map/1";
  }

  skip(){
    this.zurKarte();
  }

  skipper(){
    document.getElementById("starter").style.display = "none";
    document.getElementById("skip").style.right = "1em";
    document.getElementById("bauchbinde").style.left = "1em";
    setTimeout(function () {
      document.getElementById("bauchbinde").style.left = "-100em";
    }, 5000);
  }

  updatePosition(posId){
    this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId});
  }
}
