<app-navigation></app-navigation>
<app-profile-nav></app-profile-nav>

<body>
  <!--  <div class="container">
        <div class="sponsorenElement">
            <h2>Wir bedanken uns herzlich bei den Sponsoren<br>für die Unterstützung der BAT-Jahrestagung 2020</h2>
        </div>
        <div class="sponsorenElement" *ngFor="let image of bilder">
            <img src="assets/sponsoren-bat/{{image}}" style="max-height: 5em;" />
        </div>
        <br><br><br><br><br><br><br>
    </div>
    -->
    <div class="sponsorenImg">
        <img src="assets/images/sponsoren.JPG" />
    </div>
</body>