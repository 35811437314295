import { Component, OnInit } from '@angular/core';
import { Feedback } from '../models/feedback';
import { FeedbackService } from '../services/feedback.service';
declare var $;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  feedback: Feedback = new Feedback;
  question6text: string;

  constructor(
    private feedbackService: FeedbackService
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem("feedback") !== 'sent') {
      $('#feedbackModal').modal('show');
    }
  }

  save(): void {
    if (this.feedback.question6 === 'sonstiges') {
      this.feedback.question6 = this.question6text;
    }

    this.feedbackService.createFeedbacka(this.feedback).subscribe();

    $('#feedbackModal').modal('hide');
    sessionStorage.setItem("feedback", 'sent');
  }
}
