import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../_services/map.service';
import { Map } from '../../_models/map';
import {MapType} from '../../_models/map';

@Component({
  selector: 'app-map-create',
  templateUrl: './map-create.component.html',
  styleUrls: ['./map-create.component.sass']
})
export class MapCreateComponent implements OnInit {

  map: Map;
  types = MapType;
  keys = Object.keys;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.map = new Map();
  }

  save(): void {
    this.mapService.createMap(this.map)
      .subscribe();
  }

}
