import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const currentUser = this.authService.currentUserValue;

    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && !route.data.roles.includes(currentUser.role.id)) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      
      // authorised so return true
      return true;

    }
    // not logged in so redirect to login page with return url
    this.authService.redirectUrl = state.url;
    this.router.navigate(['/login']);
    return false;
  }
}
