import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Users } from '../models/users';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {DatabaseService} from '../services/database.service';
import * as moment from 'moment';
import { User } from '../admin/_models/user';
import { DecodeTokenService } from '../services/decode-token.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  redirectUrl: string;
  authUrl = this.dbUrl.returnDatabaseUrl() + 'auth';

  constructor(
    private http: HttpClient,
    private dbUrl: DatabaseService,
    private decodeService: DecodeTokenService
  ) { }

  login(loginData) {
    console.log(loginData);
    return this.http.post<{access_token: string}>(this.authUrl+'/login', loginData )
      .pipe(
        tap(res => {sessionStorage.setItem('access_token', res.access_token)}),
        catchError(this.handleError)
      );
  }


  register(loginData) {
    return this.http.post<{access_token: string}>( this.authUrl+'/login', loginData )
      .pipe(tap(res => {
        this.login(loginData)
      }))
  }

  
  logout() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('expires_at');
    window.location.href="welcomecenter";
  }

  public get loggedIn(): boolean{
    return sessionStorage.getItem('access_token') !==  null;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    window.alert("Email und/oder Passwort sind falsch. Bitte versuchen Sie es erneut.")
    return throwError(
      'Something bad happened; please try again later.');
  }
  
  private setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn,'second');
    sessionStorage.setItem('access_token', authResult.accessToken);
    sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }        
  
  getExpiration() {
    const expiration = sessionStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }    

  public isLoggedIn() {
    if(sessionStorage.getItem("access_token")){
      return true;
    }else{
      return false;
    }
    //return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
      return !this.isLoggedIn();
  }

  public get currentUserValue(): User {
    if (this.isLoggedIn) {
      const token =  sessionStorage.getItem('access_token');
      return this.decodeService.getUser(token);
    }
  }
}