import { Component, OnChanges, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { Chatroom } from '../models/chatroom';
import { Chatmessage } from '../models/chatmessage';
import {BenachrichtigungService} from '../services/benachrichtigung.service'
import { DecodeTokenService } from '../services/decode-token.service';
import { Users } from '../models/users';
import { UsersService } from '../services/users.service';
declare var $;

@Component({
  selector: 'app-private-chat',
  templateUrl: './private-chat.component.html',
  styleUrls: ['./private-chat.component.css']
})
export class PrivateChatComponent implements OnInit, OnChanges {
  @Output() privateChatEmit = new EventEmitter<string>();
  selectedCreateUser: any = 'Bitte Benutzer auswählen';
  chatSuche;
  userSuche;
  rooms: any;
  userId: any;
  privateChatroomId: any;
  userList: any;
  public chatmessagesPvtShow = new Array();
  public chatmessagesPvt = new Map();
  public neueNachrichten = new Array();

  constructor( private chatService: ChatService, private decodeTokenService: DecodeTokenService, private usersService: UsersService) { }

  sendMessageToParent(message: string) {
    this.privateChatEmit.emit(message)
}

  ngOnInit(): void {
    this.chatService.getChatrooms()
      .subscribe((rooms) => {this.rooms = rooms; this.testeUngelesen();});
    this.userId = this.decodeTokenService.decodeUserId().toString();
    
    this.chatService.receiveChatmessage().subscribe((chatmessagePvt: Chatmessage) => {
      this.chatmessagesPvt.set(chatmessagePvt, chatmessagePvt.chatroom.id);
      localStorage.setItem('nachricht'+chatmessagePvt.chatroom.id, 'ungelesen');
      this.chatmessagesPvt.forEach((value, key, map) =>{
        if(value === this.privateChatroomId){
          this.chatmessagesPvtShow.push(key);
          this.chatmessagesPvt.clear();
        }
      });
      this.chatService.getChatrooms()
      .subscribe((rooms) => {this.rooms = rooms; setTimeout(this.testeUngelesen, 1000);});
      

      this.testeUngelesen();
      // console.log(this.chatmessagesPvt);
      this.scroll();
      this.scroll();
    });
    //setInterval(()=>{this.chatService.getChatrooms().subscribe((rooms) => {this.rooms = rooms;});}, 10000);
    setTimeout(this.testeUngelesen, 1000);
    
  }


  ngOnChanges(): void {
    this.chatService.getChatrooms()
      .subscribe((rooms) => {this.rooms = rooms; this.testeUngelesen();});
    /*setInterval(()=>{
      this.chatService.getChatrooms().subscribe((rooms) => {this.rooms = rooms; });
      this.testeUngelesen();
    }, 10000);*/
  }

  testeUngelesen(){
    for (let chatrooms = 0; chatrooms < 10000; chatrooms++) {
      try {
        let element = document.getElementById('chatroom'+chatrooms);
        if(localStorage.getItem('nachricht'+chatrooms) === 'ungelesen'){
          element.classList.add('newMessage');
          this.sendMessageToParent('Neue private Nachricht!');
        }
      
      } catch (error) {
        
      }
      
    }
  }



  openPrivateChat(id: any){
    this.privateChatroomId = id;
    this.chatmessagesPvt.clear();
    this.chatmessagesPvtShow = [];
    localStorage.removeItem('nachricht'+id);
    this.chatService.getChatMessages(this.privateChatroomId).subscribe((chatmessagePvt: any) => {
      this.chatmessagesPvtShow = chatmessagePvt;
      // console.log(this.chatmessagesPvtShow);
      const eingabe = document.getElementById("eingabefeldPvt") as HTMLInputElement;
      eingabe.addEventListener('keydown', (e)=>{if (13 == e.keyCode) {
          this.sendMessagePvt(eingabe.value);
          eingabe.value = '';
     }});
     this.scroll();
     this.chatService.getChatrooms()
      .subscribe(rooms => this.rooms = rooms);
      setTimeout(this.testeUngelesen, 1000);
      
    });
    
    const uebersicht = document.getElementById('uebersichtPrivateChats');
    const einzelchat = document.getElementById('privateChat');
    uebersicht.style.display = 'none';
    einzelchat.style.display = 'block';

    
  }

  zurUebersichtId(id){
    this.zurUebersicht();
    localStorage.removeItem('nachricht'+id);
  }

  zurUebersicht(){
    const uebersicht = document.getElementById('uebersichtPrivateChats');
    const einzelchat = document.getElementById('privateChat');
    const createChatroom = document.getElementById('createChatroom');    
    uebersicht.style.display = 'block';
    einzelchat.style.display = 'none';
    createChatroom.style.display = 'none';
    this.chatService.getChatrooms()
      .subscribe(rooms => this.rooms = rooms);
      setTimeout(this.testeUngelesen, 1000);
  }

  scroll(){
    try {
      $('#myList2').animate({scrollTop: $('#myList2').prop("scrollHeight")}, 1000);
    } catch (error) {} 
  }

  sendMessagePvt(content) {
    if(content != ''){
      //Chatroom Id
      const id = this.privateChatroomId;
      setTimeout(()=>{localStorage.removeItem('nachricht'+id);}, 1000);
      const chatroom = {id} as Chatroom;
      const chatmessage = {content, chatroom} as Chatmessage;
      this.chatService.sendChatmessage(chatmessage);
      // console.log(chatmessage);
    }
  }

  startNewChat(){
    const uebersicht = document.getElementById('uebersichtPrivateChats');
    const createChatroom = document.getElementById('createChatroom');
    uebersicht.style.display = 'none';
    createChatroom.style.display = 'block';
    this.usersService.getUsers().subscribe((userList)  => this.userList = userList);
  }

  createRoom(id){
    let users = new Array();
    users.push({'id': this.decodeTokenService.decodeUserIdInt()});
    users.push({'id': id});
    // console.log(users);
    this.chatService.createChatroom(users);
    const eingabe = document.getElementById("suchleisteUser") as HTMLInputElement;
    eingabe.value = '';
    this.chatService.getChatrooms()
      .subscribe(rooms => this.rooms = rooms);
    this.zurUebersicht();
  }

  selectUser(id){
    this.selectedCreateUser = id;
    // console.log(id);
  }

  testton(url):void{
    new Audio(url).play();
  }

  ergebnisseAnzeigen(input){
    if(input === ''){
      document.getElementById("userErgebnisse2").style.display = "none";
    }else{
      document.getElementById("userErgebnisse2").style.display = "block";
    }
  }

}