import { Users } from './users';

export class Feedback {
    id: number;
    user: Users;
    question1: number;
    question2: string;
    question31: number;
    question32: number;
    question33: number;
    question34: number;
    question35: string;
    question4: string;
    question5: string;
    question6: string;
}