<div id="uebersichtPrivateChats">
    <div id="suche">
        <input class="form-control mr-sm-2" id="suchleisteChat" type="search" placeholder="Chat suchen..." aria-label="Search" [(ngModel)]="chatSuche"  data-toggle="tooltip" data-placement="top" data-html="true" title="Suchen Sie nach Chats" > 
    </div>
    <div id="chatraumListe">
        <div class="chatraumListenElement" id="chatroom{{room?.id}}" (click)="openPrivateChat(room?.id)" *ngFor="let room of rooms |filter:chatSuche">
            Chat mit 
            <div class="participants" *ngFor="let user of room.users">
                <div *ngIf="userId != user?.id" title="{{room?.id}}" class="fett">{{user?.firstName}} {{user?.lastName}} </div>
            </div>
            ({{room.id}})
        </div>
        
    </div>
    <div class="chatraumListenElement createNewChat" (click)="startNewChat()">
        Neuen Chat eröffnen
    </div>
</div>

<div id="createChatroom" style="display: none;">
    <div style="padding: 10px; background-color: white; z-index: 10;">
        <button class="btn btn-outline-secondary" (click)="zurUebersicht()">zurück</button>
        <h3 style="display: inline; vertical-align: middle;">  Neuen Chat eröffnen</h3>
    </div>
    <div id="userListe">
        <div id="userSuche">
            <input class="form-control mr-sm-2" id="suchleisteUser" type="search" placeholder="Benutzer suchen..." aria-label="Search" [(ngModel)]="userSuche" (input)="ergebnisseAnzeigen(userSuche)"  data-toggle="tooltip" data-placement="top" data-html="true" title="Suchen Sie nach Benutzern" > 
        </div>
        <div id="userErgebnis">{{selectedCreateUser}}</div>
        <div id="userErgebnisse2">
            <div class="userErgebnisse" *ngFor="let user of userList |filter:userSuche" (click)="createRoom(user.id)">
                {{user?.firstName}} {{user?.lastName}}
            </div>
        </div>
    </div>
</div>

<div class="privateChat" id="privateChat" style="display: none;">
    <div style="padding: 10px; background-color: white; z-index: 10;">
        <button class="btn btn-outline-secondary" (click)="zurUebersichtId(privateChatroomId)">zurück</button>
        <h3 style="display: inline; vertical-align: middle;">  Chatroom {{privateChatroomId}}</h3>
    </div>


 <div id="privateNachrichten">
        <ul id="myList2">
            <li *ngFor="let chatmessagePvt of chatmessagesPvtShow" >
                <div *ngIf="chatmessagePvt?.sender.id == userId" class="dummy speech-bubble-self"><italic>{{chatmessagePvt?.created | date:'shortTime'}}: </italic><bold class="fett">{{chatmessagePvt?.sender.firstName}} {{chatmessagePvt?.sender.lastName}}: </bold>{{chatmessagePvt?.content}}</div>
                <div *ngIf="chatmessagePvt?.sender.id != userId" class="dummy speech-bubble"><italic>{{chatmessagePvt?.created | date:'shortTime'}}: </italic><bold class="fett">{{chatmessagePvt?.sender.firstName}} {{chatmessagePvt?.sender.lastName}}: </bold>{{chatmessagePvt?.content}}</div>
            </li>
        </ul>
        <div id="inputs">
            <div class="input-group mb-3">
                <input type="text" class="form-control" id="eingabefeldPvt" placeholder="Nachricht..." aria-label="Nachricht..." aria-describedby="basic-addon2" #chatmessageContentPvt>
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="sendMessagePvt(chatmessageContentPvt.value); chatmessageContentPvt.value=''">Senden</button>
                </div>
            </div>
        </div>
    </div>
        


    
</div>