import { Component, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-map-redirect',
  templateUrl: './map-redirect.component.html',
  styleUrls: ['./map-redirect.component.css']
})
export class MapRedirectComponent implements OnInit, OnChanges {

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.router.navigate(['map/'+id]);
  }

  ngOnChanges(): void {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.router.navigate(['map/'+id]);
  }


}
