import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Media } from '../models/media';
import {DatabaseService} from './database.service';

@Injectable({ providedIn: 'root' })
export class MediaService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private dbUrl: DatabaseService) {}
  private mapUrl = this.dbUrl.returnDatabaseUrl()+'media';  // URL to web api
  

  /** GET maps from the server */
  getMedia(id) {
      if (id == null) {
        return this.http.get(this.mapUrl)
        .pipe(
            catchError(this.handleError)
          );
      } else {
        return this.http.get(this.mapUrl+'/'+id)
        .pipe(
            catchError(this.handleError)
          );
      }
    

  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }



}


