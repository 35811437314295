import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  closeWindow(){
    const folder = document.getElementById('folder');
    folder.style.transform = "scale(0)";
  }

  openWindow(){
    const folder = document.getElementById('folder');
    folder.style.transform = "scale(1)";
  }

}
