import { Injectable, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import {DatabaseService} from '../services/database.service';
import { Chatmessage } from '../models/chatmessage';
import { Map } from '../admin/_models/map';

@Injectable({
  providedIn: 'root'
})
export class GatewayService implements OnInit{
  constructor(private socket: Socket, private databaseService: DatabaseService) { }

  ngOnInit(){}
  sendInteraction(reaction: string){
    this.socket.emit('interaction', reaction);
  }
  receiveUsers() {
    return this.socket.fromEvent('users');
  }
  receiveInteraction(){
    return this.socket.fromEvent('interaction');
  }


  //CHAT AB HIER
  receiveUserid(){
    return this.socket.fromEvent('user_id');
  }

  receiveChatmessage(){
    return this.socket.fromEvent('chatmessage');
  }

  sendChatmessage(chatmessage: Chatmessage) {
    this.socket.emit('chatmessage', chatmessage);
  }

  connect() {
    this.socket.ioSocket.io.opts.query = { token: sessionStorage.getItem('access_token')};
    this.socket.ioSocket.io.uri =  this.databaseService.returnDatabaseUrlWithoutSlash();
    this.socket.connect();
  }

  joinRoom(roomId) {
      this.socket.emit('join', roomId);
  }

  leaveRoom(roomId) {
    this.socket.emit('leave', roomId);
  }

  receivePublicmessage() {
    return this.socket.fromEvent('publicmessage');
  }

  sendPublicmessage(chatmessage: Chatmessage) {
    this.socket.emit('publicmessage', chatmessage);
  }

  onRoomJoin(){
    return this.socket.fromEvent('join');
  }


  // QUESTION CHAT
  sendQuestion(chatmessage: Chatmessage) {
    this.socket.emit('question', chatmessage);
  }

  receiveQuestion() {
    return this.socket.fromEvent('question');
  }


  // VOTING
  sendStartVoting(startMessage: {id: number, startdate: Date, map: Map}): void {
    this.socket.emit('startVoting', startMessage);
  }

  sendEndVoting(endMessage: {id: number, expiredate: Date, map: Map}): void {
    this.socket.emit('endVoting', endMessage);
  }

  receiveVoting() {
    return this.socket.fromEvent('votingStarted');
  }

  receiveVotingEnd() {
    return this.socket.fromEvent('votingEnded');
  }
}
