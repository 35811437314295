<body>

    <div id="menu2">
        <nav id="nav2">
    
            <div id="agendaopener" (mouseover)="openNav2()" data-html="true">
                PROGRAMM-<br>ÜBERSICHT<br>>>
            </div>
    
        </nav>
    
    </div>
    
    <div id="mySidenav2" class="sidenav2">
        <nav class="navbar sticky-top navbar-light bg-light justify-content-between">
            <a class="navbar-brand schwarzText">  PROGRAMMÜBERSICHT</a>
            <div class="form-inline">
                
                <div id="suche">
                    <input class="form-control mr-sm-2" id="suchleiste" type="search" placeholder="Termin suchen..." aria-label="Search" [(ngModel)]="term"  data-toggle="tooltip" data-placement="top" data-html="true" title="Suchen Sie nach Terminen" >
                    <!--<button class="btn btn-outline-success my-2 my-sm-0" type="submit">Suchen</button>-->
                    
                </div>
                <div id="platzhalter" style="width: 2em;"></div>
              <!--  <button id="fav" (click)="favoritenAnzeigen()"><img src="assets/images/stern.png" style="max-width: 3em; max-height: 3em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Favoriten" /></button> -->
                <button id="agendaFullscreen" (click)="agendaFullscreen()"><img src="assets/images/fullscreen.png" style="max-width: 2em; max-height: 2em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Vollbild" /></button>
                <button id="agendaMinimize" (click)="agendaMinimize()"><img src="assets/images/minimize.png" style="max-width: 2.5em; max-height: 2.5em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Minimieren" /></button>
                <button id="closeNav" (click)="closeNav2()"><img src="assets/images/close.png" style="max-width: 3em; max-height: 3em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Schließen" /></button>
                <button id="closeNavMobile" (click)="closeNavMobile()"><img src="assets/images/close.png" style="max-width: 3em; max-height: 3em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Schließen" /></button>
                
            </div>
          </nav>
        <div id=Agenda>

                <table style="width:100%;">
                    <tr class="unselectable">
                      <th>Beginn</th>
                      <th>Termin</th>
                    
                    </tr>
    
                            <tr *ngFor = "let termin of agendaListe |filter:term" id="agenda{{termin.id}}" class="unselectable" data-toggle="tooltip" data-placement="top" data-html="true">
                                <td style="min-width:6em" id="termin{{termin.id}}">{{termin.startTime}}</td>
                                <td><a href="/map/{{termin.location.id}}">{{termin.name}}</a></td>
                               
                              </tr>
    
                    
                    
                  </table>
                  <div id="eigeneTermine">
    
                </div>
                </div>




                <div id=Agenda2>

                    <table style="width:100%;" class="favoritenTable">
                        <tr class="unselectable headlineFav">
                          <th>*</th>
                          <th>Datum</th>
                          <th>Persönliche Programmübersicht</th>
                        </tr>
        
                        <tr *ngFor = "let termin of persAgenda |filter:term" id="agendaFav{{termin.id}}" class="unselectable" (click)="entfernen(termin.id)">
                          <td><img src="assets/images/star.png" style="max-height: 1em; margin-bottom: -50px; margin-top: 3px;" id="bild{{termin.id}}2" /></td>
                          <td style="min-width:6em">{{termin.startTime | date:'EEEE, d.M., HH:ss'}} Uhr</td>
                          <td>{{termin.name}} in: {{termin.location.name}}</td>
                        </tr>
        
                        
                        
                      </table>
                      <div id="eigeneTermine">
        
                    </div>
                    </div>
        




    </div>
    
    </body>