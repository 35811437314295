import { Component, OnInit } from '@angular/core';
import { MediaService } from '../services/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UsersService } from '../services/users.service';
import { DecodeTokenService } from '../services/decode-token.service';
declare var $;

@Component({
  selector: 'app-mediacenter',
  templateUrl: './mediacenter.component.html',
  styleUrls: ['./mediacenter.component.css']
})
export class MediacenterComponent implements OnInit {
  medien: any;
  featured: any;
  mediaSuche;
  public url: string;
  public imgUrl: string;
  mediaAlt: string;
  constructor(
    private mediaService: MediaService, 
    private domSanitizer: DomSanitizer, 
    private usersService: UsersService, 
    private decodeTokenService: DecodeTokenService
  ) { }

  ngOnInit(): void {
    this.mediaService.getMedia(null)
    .subscribe(medien => this.medien = medien);
    this.mediaService.getMedia(null)
    .subscribe(featured => this.featured = featured);
    // this.updatePosition('8');
  }

  filtern(typ){
    this.mediaSuche = typ;
  }

  openModal(url){
    document.getElementById('id01').style.display='block';
    this.url = url;
  }

  openModal2(url){
    document.getElementById('id02').style.display='block';
    this.imgUrl = url;
  }

  open(mediaUrl, mediaId){
    if (this.mediaAlt == mediaId) {
      for (let index = 0; index < this.medien.length +10; index++) {
        try {
          this.close('', index);
          this.mediaAlt = null;
          let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + index + "']");
          videoPlayer.pause();
        } catch (error) {}
  
      }
    }else{
    for (let index = 0; index < this.medien.length +10; index++) {
      try {
        this.close('', index);
        let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + index + "']");
        videoPlayer.pause();
      } catch (error) {}

    }
    document.getElementById(mediaId).style.width = "80%";
    document.getElementById(mediaId).style.height = "35em";
    document.getElementById(mediaId).style.objectFit = "contain";
    this.mediaAlt = mediaId;
    let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + mediaId + "']");
    videoPlayer.play();
  }}

  close(mediaUrl, mediaId){
    document.getElementById(mediaId).style.width = "20%";
    document.getElementById(mediaId).style.height = "8em";
    document.getElementById(mediaId).style.objectFit = "cover";
  }
  getSource(url){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  updatePosition(posId){
    this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId});
  }

}
