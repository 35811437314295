<div>
    <h3>Benutzer</h3>

    <p>
        <a type="button" class="btn btn-primary mr-1" routerLink="/admin/user/create">Neu erstellen</a>
        <a type="button" class="btn btn-secondary ml-1" routerLink="/admin/user/createbatch">CSV Import</a>
    </p>
    
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Vorname</th>
                <th scope="col">Nachname</th>
                <th scope="col">E-Mail</th>
                <th scope="col">Firma</th>
                <th scope="col">Rolle</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let user of users; let i = index" class="mouse-pointer" routerLink="/admin/user/edit/{{user.id}}">
                <th scope="row">{{user.id}}</th>
                <td>{{user.firstName}}</td>
                <td>{{user.lastName}}</td>
                <td>{{user.eMail}}</td>
                <td>{{user?.company?.name}}</td>
                <td>{{user?.role?.id}}</td>
            </tr>
        </tbody>
    </table>
</div>