<body>

    <div id="menu2">
        <nav id="nav2">
            <!--
            <div id="chatopener" (mouseover)="openNav()">
                CHAT <br> <<
                    
            </div>
    -->
        
            <ul id="nav2ul">
              
                <li><a href="/map/1" id="mapButtonPopover" data-toggle="tooltip" data-placement="top" data-html="true" title="Zur Karte"><button type="button" id="navbtn"><img src="assets/images/Map.png" /></button></a></li>

        
            </ul>
        </nav>
    
    </div>
    
    
    
    
  


    </body>

    <app-voting-frontend></app-voting-frontend>