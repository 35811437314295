<h3>Karte {{map.name}}</h3>

<form (ngSubmit)="save()" #mapCreateForm="ngForm"> 
    <div class="form-row">
        <div class="col-sm-1 mb-3">
            <label for="inputId" disabled>ID</label>
            <input type="text" class="form-control" id="inputId" [(ngModel)]="map.id" name="id" placeholder="ID" readonly>
        </div>
        <div class="col-sm-7 mb-3">
            <label for="inputName" >Name</label>
            <input type="text" class="form-control" id="inputName" [(ngModel)]="map.name" name="name" placeholder="Name">
        </div>
        <div class="col-sm-4">
            <label for="selectType">Typ</label>
            <select class="form-control" id="selectType" [(ngModel)]="map.type" name="type">
                <option *ngFor="let symbol of keys(types)" [value]="symbol">{{types[symbol]}}</option>
            </select>
        </div>
    </div>
    

    <div class="form-group">
        <label for="inputDescription">Beschreibung</label>
        <textarea class="form-control" id="inputDescription" [(ngModel)]="map.description" name="description" placeholder="Beschreibung" rows="3"></textarea>
    </div>

    <h4>Hintergrund</h4>

    MISSING: Background-Image preview and selection!

    <div class="form-group">
        <input type="file" class="form-control-file" id="inputBackgroundImage">
    </div>
    
    <div class="form-row">
        <div class="col-sm-2 mb-3">
            <label for="inputBackgroundHeight">Höhe</label>
            <input type="number" class="form-control" id="inputBackgroundHeight" [(ngModel)]="map.backgroundHeight" name="backgroundHeight" placeholder="Höhe">   
        </div>
        <div class="col-sm-2 mb-3">
            <label for="inputBackgroundWidth">Breite</label>
            <input type="number" class="form-control" id="inputBackgroundWidth" [(ngModel)]="map.backgroundWidth" name="backgroundWidth" placeholder="Breite">   
        </div>        
    </div>

    <h4>Icon</h4>

    MISSING: Icon-Image preview and selection!
    <div class="form-group">
        <input type="file" class="form-control-file" id="inputIconImage">
    </div>
    

    <h4>Start-Medium</h4>

    MISSING: Startup-Media preview and selection!

    <div class="form-group">
        <input type="file" class="form-control-file" id="inputStartupMedia">
    </div>


    <h4>Zoom-Einstellungen</h4>
    <div class="form-group">
        <label for="inputZoomDefault">Standard</label>
        <input type="number" class="form-control" id="inputZoomDefault" [(ngModel)]="map.zoomDefault" name="zoomDefault" placeholder="1">
    </div>
    <div class="form-row">
        <div class="col-sm-2 mb-3">
            <label for="inputZoomMin">Minimal</label>
            <input type="number" class="form-control" id="inputZoomMin" [(ngModel)]="map.zoomMin" name="zoomMin" placeholder="min">   
        </div>
        <div class="col-sm-2 mb-3">
            <label for="inputZoomMax">Maximal</label>
            <input type="number" class="form-control" id="inputZoomMax" [(ngModel)]="map.zoomMax" name="zoomMax" placeholder="max">   
        </div>
    </div>

    <h4>Mitte</h4>
    <div class="form-row">
        <div class="col-sm-2 mb-3">
            <label for="inputMidX">X</label>
            <input type="number" class="form-control" id="inputMidX" [(ngModel)]="map.midX" name="midX" placeholder="X">   
        </div>
        <div class="col-sm-2 mb-3">
            <label for="inputMidY">Y</label>
            <input type="number" class="form-control" id="inputMidY" [(ngModel)]="map.midY" name="midY" placeholder="Y">   
        </div>
    </div>

    <button type="submit" class="btn btn-success" [disabled]="!mapCreateForm.form.valid">Speichern</button>
</form>
    
<!--
<div class="card my-2">
    <h5 class="card-header">Verknüpfte Firmen</h5>
    <div class="card-body">

        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"><button (click)="addCompany()" class="btn btn-primary my-2">Hinzufügen</button></th>
                    <th scope="col">Firmenname</th>
                    <th scope="col">Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let company of map.companies">
                    <td>LOGO</td>
                    <td>{{company.name}}</td>
                    <td><button (click)="removeCompany(company.id)" class="btn btn-outline-danger">Entfernen</button></td>
                </tr>
            </tbody>
            
        </table>

    </div>
</div>



<div class="card my-2">
    <h5 class="card-header">Verknüpfte Karten</h5>
    <div class="card-body">

        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"><button (click)="addMapChild()" class="btn btn-primary">Hinzufügen</button></th>
                    <th scope="col">Karte</th>
                    <th scope="col">X</th>
                    <th scope="col">Y</th>
                    <th scope="col">Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mapChild of map.mapChilds">
                    <td>{{mapChild.icon}}</td>
                    <td>{{mapChild.childMap.name}}</td>
                    <td>{{mapChild.positionX}}</td>
                    <td>{{mapChild.positionY}}</td>
                    <td>
                        <button (click)="editMapChild(mapChild.id)" class="btn btn-outline-secondary">Bearbeiten</button>
                        <button (click)="removeMapChild(mapChild.id)" class="btn btn-outline-danger">Entfernen</button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>

<div class="card my-2">
    <h5 class="card-header">Verknüpfte Medien</h5>
    <div class="card-body">

        <table class="table table-hover">
            <thead>
                <tr>
                    <th scope="col"><button (click)="addMedia()" class="btn btn-primary">Hinzufügen</button></th>
                    <th scope="col">Thumbnail</th>
                    <th scope="col">X</th>
                    <th scope="col">Y</th>
                    <th scope="col">Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let media of map.media">
                    <td>{{media.icon}}</td>
                    <td>{{media.url}}</td>
                    <td>{{media.positionX}}</td>
                    <td>{{media.positionY}}</td>
                    <td>
                        <button (click)="editMedia(media.id)" class="btn btn-outline-secondary">Bearbeiten</button>
                        <button (click)="removeMedia(media.id)" class="btn btn-outline-danger">Entfernen</button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>
-->