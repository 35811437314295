<button type="button" class="btn btn-primary btn-on-top" data-toggle="modal" data-target=".modal-feedback-lg">Feedback abgeben</button>


<div class="modal fade modal-feedback-lg" id="feedbackModal" tabindex="-1" role="dialog" aria-labelledby="feedbackModal" aria-hidden="true" show="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            
            <div class="modal-header">
                <h5 class="modal-title">Feedback zur Tagung</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8">
                        <p>
                            1 = gar nicht gut, 5 = sehr gut
                        </p>
                    </div>
                </div>
               
                <form #feedbackForm="ngForm">

                    <!-- Question 1 -->
                    <fieldset class="form-group">
                        <div class="row">
                            <legend class="col-form-label col-sm-4">Wie gefiel Ihnen die Veranstaltung insgesamt?</legend>
                            <div class="col-sm-8">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="question1answer1" id="question1answer1" [(ngModel)]="feedback.question1" [value]="1">
                                    <label class="form-check-label" for="question1answer1">1</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="question1answer2" id="question1answer2" [(ngModel)]="feedback.question1" [value]="2">
                                    <label class="form-check-label" for="question1answer2">2</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="question1answer3" id="question1answer3" [(ngModel)]="feedback.question1" [value]="3">
                                    <label class="form-check-label" for="question1answer3">3</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="question1answer4" id="question1answer4" [(ngModel)]="feedback.question1" [value]="4">
                                    <label class="form-check-label" for="question1answer4">4</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="question1answer5" id="question1answer5" [(ngModel)]="feedback.question1" [value]="5">
                                    <label class="form-check-label" for="question1answer5">5</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
    
                    <!-- Question 2 -->
                    <div class="form-group row">
                        <label for="inputQuestion2" class="col-sm-4 col-form-label">Welche fachlichen Themen haben Sie besonders interessiert?</label>
                        <div class="col-sm-8">
                            <textarea class="form-control" id="inputQuestion2" [(ngModel)]="feedback.question2" name="question2" placeholder="" rows="3" maxlength="255"></textarea>
                        </div>
                    </div>
    
                    <!-- Question 3 -->
                    <h6 >Fragen zur Benutzeroberfläche</h6>
                    <div class="form-group pb-3 ml-3">

                        <!-- Question 3.1 -->
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-4">Wie haben Sie sich zurechtgefunden?</legend>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question31answer1" id="question31answer1" [(ngModel)]="feedback.question31" [value]="1">
                                        <label class="form-check-label" for="question31answer1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question31answer2" id="question31answer2" [(ngModel)]="feedback.question31" [value]="2">
                                        <label class="form-check-label" for="question31answer2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question31answer3" id="question31answer3" [(ngModel)]="feedback.question31" [value]="3">
                                        <label class="form-check-label" for="question31answer3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question31answer4" id="question31answer4" [(ngModel)]="feedback.question31" [value]="4">
                                        <label class="form-check-label" for="question31answer4">4</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question31answer5" id="question31answer5" [(ngModel)]="feedback.question31" [value]="5">
                                        <label class="form-check-label" for="question31answer5">5</label>
                                    </div>
                                </div>
                            </div>  
                        </fieldset>
                        
    
                        <!-- Question 3.2 -->
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-4">Wie einfach war die Anmeldung?</legend>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question32answer1" id="question32answer1" [(ngModel)]="feedback.question32" [value]="1">
                                        <label class="form-check-label" for="question32answer1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question32answer2" id="question32answer2" [(ngModel)]="feedback.question32" [value]="2">
                                        <label class="form-check-label" for="question32answer2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question32answer3" id="question32answer3" [(ngModel)]="feedback.question32" [value]="3">
                                        <label class="form-check-label" for="question32answer3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question32answer4" id="question32answer4" [(ngModel)]="feedback.question32" [value]="4">
                                        <label class="form-check-label" for="question32answer4">4</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question32answer5" id="question32answer5" [(ngModel)]="feedback.question32" [value]="5">
                                        <label class="form-check-label" for="question32answer5">5</label>
                                    </div>
                                </div>
                            </div>  
                        </fieldset>
    
                        <!-- Question 3.3 -->
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-4">War die Benutzeroberfläche intuitiv und leicht bedienbar?</legend>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question33answer1" id="question33answer1" [(ngModel)]="feedback.question33" [value]="1">
                                        <label class="form-check-label" for="question33answer1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question33answer2" id="question33answer2" [(ngModel)]="feedback.question33" [value]="2">
                                        <label class="form-check-label" for="question33answer2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question33answer3" id="question33answer3" [(ngModel)]="feedback.question33" [value]="3">
                                        <label class="form-check-label" for="question33answer3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question33answer4" id="question33answer4" [(ngModel)]="feedback.question33" [value]="4">
                                        <label class="form-check-label" for="question33answer4">4</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question33answer5" id="question33answer5" [(ngModel)]="feedback.question33" [value]="5">
                                        <label class="form-check-label" for="question33answer5">5</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
    
                        <!-- Question 3.4 -->
                        <fieldset class="form-group">
                            <div class="row">
                                <legend class="col-form-label col-sm-4">Wie waren die Möglichkeiten zur aktiven Beteiligung (Fragen stellen, Applaus)?</legend>
                                <div class="col-sm-8">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question34answer1" id="question34answer1" [(ngModel)]="feedback.question34" [value]="1">
                                        <label class="form-check-label" for="question34answer1">1</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question34answer2" id="question34answer2" [(ngModel)]="feedback.question34" [value]="2">
                                        <label class="form-check-label" for="question34answer2">2</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question34answer3" id="question34answer3" [(ngModel)]="feedback.question34" [value]="3">
                                        <label class="form-check-label" for="question34answer3">3</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question34answer4" id="question34answer4" [(ngModel)]="feedback.question34" [value]="4">
                                        <label class="form-check-label" for="question34answer4">4</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="question34answer5" id="question34answer5" [(ngModel)]="feedback.question34" [value]="5">
                                        <label class="form-check-label" for="question34answer5">5</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
    
                        <!-- Question 3.5 -->
                        <div class="form-group row">
                            <label for="inputQuestion35" class="col-sm-4 col-form-label">Was haben Sie auf der Benutzeroberfläche vermisst?</label>
                            <div class="col-sm-8">
                                <textarea class="form-control" id="inputQuestion35" [(ngModel)]="feedback.question35" name="question35" placeholder="" rows="3" maxlength="255"></textarea>
                            </div>
                        </div>
                    </div>
    
                        <!-- Question 4 -->
                        <div class="form-group row">
                            <label for="inputQuestion4" class="col-sm-4 col-form-label">Was hat Ihnen besonders gefallen, was hätte man besser machen sollen?</label>
                            <div class="col-sm-8">
                                <textarea class="form-control" id="inputQuestion4" [(ngModel)]="feedback.question4" name="question4" placeholder="" rows="3" maxlength="255"></textarea>
                            </div>
                        </div>
    
                        <!-- Question 5 -->
                        <div class="form-group row">
                            <label for="inputQuestion5" class="col-sm-4 col-form-label">Welche Elemente sollen wir für künftige Tagungen unbedingt beibehalten?</label>
                            <div class="col-sm-8">
                                <textarea class="form-control" id="inputQuestion5" [(ngModel)]="feedback.question5" name="question5" placeholder="" rows="3" maxlength="255"></textarea>
                            </div>
                        </div>
    
                        <!-- Question 6 -->
                        <div class="form-group row">
                            <label for="inputQuestion6" class="col-sm-4 col-form-label">Freiwillige Angabe: Ich war Tagungsteilnehmer aus dem folgenden Bereich</label>
                            <div class="col-sm-8">
                                <div class="col-sm-8">
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer1" id="question6answer1" [(ngModel)]="feedback.question6" [value]="'forschung'">
                                        <label class="form-check-label" for="question6answer1">Forschung (Landesanstalten, Fachhochschulen, Universitäten)</label>
                                    </div>
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer2" id="question6answer2" [(ngModel)]="feedback.question6" [value]="'behoerde'">
                                        <label class="form-check-label" for="question6answer2">Behörden mit überwiegend administrativer Aufgabe, Ministerien etc.</label>
                                    </div>
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer3" id="question6answer3" [(ngModel)]="feedback.question6" [value]="'industrie'">
                                        <label class="form-check-label" for="question6answer3">Industrie, Handel</label>
                                    </div>
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer4" id="question6answer4" [(ngModel)]="feedback.question6" [value]="'landwirtschaft'">
                                        <label class="form-check-label" for="question6answer4">Landwirtschaftliche Praxis</label>
                                    </div>
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer5" id="question6answer5" [(ngModel)]="feedback.question6" [value]="'presse'">
                                        <label class="form-check-label" for="question6answer5">Presse</label>
                                    </div>
                                    <div class="form-check ">
                                        <input class="form-check-input" type="radio" name="question6answer6" id="question6answer6" [(ngModel)]="feedback.question6" [value]="'sonstiges'">
                                        <label class="form-check-label" for="question6answer6">Sonstiges (bitte angeben)...</label>
                                        <input type="text" class="form-control" id="inputQuestion6answer6" [(ngModel)]="question6text" name="inputQuestion6answer6">
                                    </div>
                                </div>
                            </div>
                        </div>
                </form>
            </div>
           
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary" (click)="save()" data-dismiss="modal">Senden</button>
            </div>
        </div>
    </div>
</div>