import { Component, OnInit } from '@angular/core';
import { AgendaService } from '../services/agenda.service'
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { DecodeTokenService } from '../services/decode-token.service';
declare var $;


@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  today: any;
  agenda: any;
  agenda2: any;
  filteredItems: any;
  items: any;
  persAgenda: any;
  term;
  public agendaListe = new Array();
  constructor(private agendaService: AgendaService, private decodeTokenService: DecodeTokenService) { }
  searchText = '';
  ngOnInit(): void {
    setTimeout(this.refreshFavs, 5000);
    this.getAgenda();
    
    this.favoriten = 0;
    if(localStorage.getItem('agendaTooltip')){
      $('#agendaopener').tooltip('disable');
    }else{
      $('#agendaopener').tooltip('show');
    }
    $('tr').tooltip('enable');
    $('button').tooltip('enable');
    $('img').tooltip('enable');
    $('input').tooltip('enable');

    $('progress').tooltip('enable');

  }



  refreshFavsEvent(){
    for (let id = 0; id < 5000; id++) {
      try {
        if (localStorage.getItem('agenda'+id) == 'true') {       
          document.getElementById('agenda'+id).classList.add("selected");
          //document.getElementById(id+'2').classList.add("selected");
          $("#bild"+id).attr("src", 'assets/images/star.png');
          $("#bild"+id+'2').attr("src", 'assets/images/star.png');
         
        }else{
          document.getElementById('agenda'+id).classList.remove("selected");
          //document.getElementById(id+'2').classList.remove("selected");
          $("#bild"+id).attr("src", 'assets/images/star2.png');
          $("#bild"+id+'2').attr("src", 'assets/images/star2.png');
          

        }
      } catch (error) {} 
    }
  }

  getAgenda(): void{
    this.agendaListe.push({id: 1, name: 'Startfilm', location: {id: 4}, startTime: 'ganztags'});
    this.agendaListe.push({id: 2, name: 'Eröffnung und Begrüßung', location: {id: 5}, startTime: '09:00-10:00'});
    this.agendaListe.push({id: 4, name: 'Sektion Pflanzenbau/Pflanzenzüchtung/Spezialkulturen/Ressourcenschutz', location: {id: 5}, startTime: '10:00-12:15'});
    this.agendaListe.push({id: 5, name: 'Sektion Schwein/Geflügel/Teich', location: {id: 20}, startTime: '10:00-12:00'});
    this.agendaListe.push({id: 6, name: 'Mittagspause', location: {id: 1}, startTime: '12:15/12:00-13:00'});
    this.agendaListe.push({id: 7, name: 'Postersektion', location: {id: 5}, startTime: '13:30-14:30'});
    this.agendaListe.push({id: 8, name: 'Pause', location: {id: 1}, startTime: '14:30-14:45'});
    this.agendaListe.push({id: 10, name: 'Sektion Rinder/Kleinwiederkäuer/Grünland, Schlusswort', location: {id: 5}, startTime: '14:45-16:30'});
    this.agendaListe.push({id: 11, name: 'Sektion Sozioökonomie/Märkte, Schlusswort', location: {id: 20}, startTime: '14:45-15:45'});
  }

getPersonalAgenda(){
  this.agendaService.getPersonalAgenda(this.decodeTokenService.decodeUserId())
    .subscribe(persAgenda => this.persAgenda = persAgenda);
}

datenVerarbeiten(data){
  this.agenda = data;
  this.refreshFavs();
}

//Wird mit OnInit aufgerufen
  refreshFavs(){
    
    for (let index = 0; index < 10000; index++) {
      
      try {
        if (this.persAgenda[index].id) {
          console.log('Refresh');
          console.log(index);
          if(this.agenda[index].id){
            console.log(this.persAgenda[index].id + ' ist aktiv');
            document.getElementById('agenda'+this.persAgenda[index].id).classList.add("selected");
            $("#bild"+this.persAgenda[index].id).attr("src", 'assets/images/star.png');
            localStorage.setItem('agenda'+this.persAgenda[index].id, 'true');
          }
      }
      } catch (error) {} 
    }
  }

  merken(id){
    this.agendaFav(id);
    this.testton('assets/sounds/confirm.wav');
  }

  entfernen(id){
    localStorage.setItem('agenda'+id, 'false');
      
      //document.getElementById(id+'2').classList.remove("selected");
      $("#bild"+id).attr("src", 'assets/images/star2.png');
      $("#bild"+id+'2').attr("src", 'assets/images/star2.png');
      this.agendaService.removeUserFromAgenda(id, this.decodeTokenService.decodeUserId());
    this.testton('assets/sounds/confirm.wav');
    this.getPersonalAgenda();
    document.getElementById('agenda'+id).classList.remove("selected");
  }

  agendaFav(id){
    if (localStorage.getItem('agenda'+id) == 'true') {
      localStorage.setItem('agenda'+id, 'false');
      document.getElementById('agenda'+id).classList.remove("selected");
      //document.getElementById(id+'2').classList.remove("selected");
      $("#bild"+id).attr("src", 'assets/images/star2.png');
      $("#bild"+id+'2').attr("src", 'assets/images/star2.png');
      this.agendaService.removeUserFromAgenda(id, this.decodeTokenService.decodeUserId());
    }else{
      localStorage.setItem('agenda'+id, 'true');
      document.getElementById('agenda'+id).classList.add("selected");
      //document.getElementById(id+'2').classList.add("selected");
      $("#bild"+id).attr("src", 'assets/images/star.png');
      $("#bild"+id+'2').attr("src", 'assets/images/star.png');
      this.agendaService.addUserToAgenda(id, this.decodeTokenService.decodeUserId());
      console.log('Agenda Nr.: '+id + ' User: '+this.decodeTokenService.decodeUserId());
    }
  }

  //Wird zu Beginn aufgerufen; hebt personal Agenda hervor
  agendaFavRef(id){
    console.log(id);
    
  }

 
  openNav2(): void{

    //aktuelle Zeit auslesen
    this.today = new Date();
    if (this.today.getMinutes() < 10) {
      this.today = this.today.getHours() + ':0' + this.today.getMinutes();
    } else{
      this.today = this.today.getHours() + ':' + this.today.getMinutes();
    }

    //document.getElementById("jetzt").innerHTML = this.today;


    
    const input = document.getElementById('suchleiste');

    input.addEventListener('input', this.refreshFavsEvent);


    document.getElementById("mySidenav2").style.left = "0px";
    this.testton('assets/sounds/slide.mp3');
    this.today = new Date();
    if (this.today.getMinutes() < 10) {
      this.today =this.today.getHours() + ':0' + this.today.getMinutes();
    } else{
      this.today =this.today.getHours() + ':' + this.today.getMinutes();
    }

    //document.getElementById("jetzt").innerHTML = this.today;
    localStorage.setItem('agendaTooltip', '1');
    $('tr').tooltip('enable');
    $('button').tooltip('enable');
    $('img').tooltip('enable');
    $('input').tooltip('enable');
    
    $('#agendaopener').tooltip('disable');
   }
  
   closeNav2(): void{
     this.agendaMinimize();
     document.getElementById("mySidenav2").style.left = "-1200px";
   }

   closeNavMobile(){
    document.getElementById("mySidenav2").style.left = "-1300px";
   }

   testton(url):void{
    var audio = new Audio(url);
    audio.volume = Number(localStorage.getItem('agendaVolume'))/100;
    audio.play();
  }
  agendaFullscreen(){
    this.testton('assets/sounds/blop.wav');
    document.getElementById("mySidenav2").style.transition = "0.5s ease";
    document.getElementById("mySidenav2").style.zIndex = "500";
    document.getElementById("mySidenav2").style.backgroundColor = "rgba(230, 230, 230, 0.98)";
    document.getElementById("mySidenav2").style.color = "var(--second-color)";
    document.getElementById("mySidenav2").style.left = "0px";
    document.getElementById("mySidenav2").style.top = "0px";
    document.getElementById("mySidenav2").style.maxWidth = "100vw";
    document.getElementById("mySidenav2").style.maxHeight = "100vh";
    document.getElementById("mySidenav2").style.width = "100vw";
    document.getElementById("mySidenav2").style.height = "100vh";
    document.getElementById("agendaFullscreen").style.display = "none";
    document.getElementById("agendaMinimize").style.display = "block";
    document.getElementById("suche").style.display = "inline";
    document.getElementById("Agenda").style.fontSize = "larger";
    document.getElementById("Agenda2").style.fontSize = "larger";
    document.getElementById("eigeneTermine").innerHTML = "";
  }

  agendaMinimize(){
    this.testton('assets/sounds/blop.wav');
    document.getElementById("mySidenav2").style.transition = "0.5s ease";
    document.getElementById("mySidenav2").style.zIndex = "500";
    document.getElementById("mySidenav2").style.backgroundColor = "var(--main-color)";
    document.getElementById("mySidenav2").style.color = "var(--second-color)";
    document.getElementById("mySidenav2").style.left = "0px";
    document.getElementById("mySidenav2").style.top = "12em";
    document.getElementById("mySidenav2").style.maxWidth = "40vw";
    document.getElementById("mySidenav2").style.maxHeight = "60%";
    document.getElementById("mySidenav2").style.width = "none";
    document.getElementById("mySidenav2").style.height = "30vh";
    document.getElementById("agendaFullscreen").style.display = "block";
    document.getElementById("agendaMinimize").style.display = "none";
    document.getElementById("suche").style.display = "none";
    document.getElementById("Agenda").style.fontSize = "smaller";
    document.getElementById("Agenda2").style.fontSize = "smaller";
    document.getElementById("eigeneTermine").innerHTML = "";
  }

  favoriten: number;
  
  favoritenAnzeigen(){
    if(this.favoriten == 0){
      this.favoriten = 1;
      this.getPersonalAgenda();
      document.getElementById("Agenda2").style.display = "block";
      document.getElementById("Agenda").style.display = "none";
      document.getElementById("fav").style.backgroundColor = "yellow";
      document.getElementById("suchleiste").style.display = "none";
      this.testton('assets/sounds/blop.wav');
    }else{
      this.favoriten = 0;
      document.getElementById("Agenda2").style.display = "none";
      document.getElementById("Agenda").style.display = "block";
      document.getElementById("fav").style.backgroundColor = "transparent";
      document.getElementById("suchleiste").style.display = "block";
      this.testton('assets/sounds/blop.wav');
    }
  }

}
