import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsoren',
  templateUrl: './sponsoren.component.html',
  styleUrls: ['./sponsoren.component.css']
})
export class SponsorenComponent implements OnInit {

  constructor() { }

  bilder: string[] = ['AGROMED.png', 'ALLTECH.jpg', 'AMA.jpg', 'BIOMIN.JPG', 'BTC.JPG', 'DELACON.png', 'DR_ECKEL.jpg', 'DSM.JPG', 'GITES groß.jpg', 'LALLEMAND.jpg', 'LKV.jpg', 'LOGO_EW NUTRITION-centred.jpg', 'ORFFA.jpg', 'PHYTOBIOTICS.png', 'PULTE.jpg', 'TROWNUTRITION.jpg', 'VETAGRO.jpg'];

  ngOnInit(): void {
  }

}
