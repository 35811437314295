import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Company } from '../admin/_models/company';
import { Role } from '../admin/_models/role';
import { User } from '../admin/_models/user';

@Injectable({ providedIn: 'root' })
export class DecodeTokenService {

    decodeUserId(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.id.toString();  
    }

    decodeUserIdInt(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.id;  
    }

    decodeFirstName(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.firstname.toString();
    }

    decodeLastName(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.lastname.toString();
    }

    decodeEmail(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.email.toString();
    }

    decodeRole(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.role.toString();
    }

    decodeCreated(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.iat.toString();
    }

    decodeExp(){
        var token = sessionStorage.getItem('access_token');
        var decoded = jwt_decode(token); 
        return decoded.exp;
    }

    decode(data){
        return jwt_decode(data);
    }

    getUser(token): User {
        try {
            const decodedToken = jwt_decode(token);

            const user = new User();
            user.id = decodedToken.id;
            user.firstName = decodedToken.firstName;
            user.lastName = decodedToken.lastName;
            user.eMail = decodedToken.email;
            user.role = new Role();
            user.role.id = decodedToken.role;
            user.company = new Company();
            user.company.id = decodedToken.company;

            return user;
        }
        catch {
            return null;   
        }
    }
}
