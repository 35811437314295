import { Component, OnInit } from '@angular/core';
import {GatewayService} from './services/gateway.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'wes';

  constructor(private gatewayService: GatewayService){}
  ngOnInit(){
    this.gatewayService.connect();
  }
}
