<div>
    <h3>Center</h3>

    <p>Klicken Sie auf ein Center, um es zu bearbeiten.</p>
    
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Beschreibung</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let map of maps" class="mouse-pointer" routerLink="/admin/center/edit/{{map.id}}">
                <th scope="row">{{map.id}}</th>
                <td>{{map.name}}</td>
                <td>{{map.description}}</td>
            </tr>
        </tbody>
    </table>
</div>
