import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users.service';
import { DecodeTokenService } from 'src/app/services/decode-token.service';
import { InteressenComponent } from 'src/app/interessen/interessen.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  loggedIn: Boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private usersService: UsersService,
    private decodeTokenService: DecodeTokenService
  ) {}
  ngOnInit(): void {
    this.loggedIn = this.authService.loggedIn;
    if(this.loggedIn){
      window.location.href="welcomecenter";
    }
  }
  onSubmit(loginData) {
    var element3 = <HTMLInputElement> document.getElementById("defaultCheck1");
    var checked = element3.checked;
    if(checked === false){window.alert('Bitte stimmen Sie unseren Nutzungsbedingungen und unserer Datenschutzerklärung zu.')}else{
    this.authService.login(loginData).subscribe(()=>{
      if (this.authService.isLoggedIn()) {
        window.location.href="welcomecenter";
        /*
        if(localStorage.getItem('interessen')){
          this.router.navigate([this.authService.redirectUrl]);
        }else{
          window.location.href="interessen";
        }
        */
      }
      else{
        window.alert('Fehler');
      }
    });
  }
}
}
