<app-cityhall-nav></app-cityhall-nav>
<!-- <app-cityhall-voting></app-cityhall-voting> -->
<app-agenda></app-agenda>
<app-profile-nav></app-profile-nav>
<app-chat></app-chat>
<body>
  <iframe id="livestream" src="https://www.welocal.world/cmms-embed/1989/" frameborder="0" allowfullscreen></iframe> 
  <!--<video id="video" width="100%" height="auto" autoplay muted>
    <source src="assets/video/trailer.mp4" type="video/mp4" />
  </video>-->


          


     <!-- 

  <div class="Ankuendigung">
    Hier findet am Donnerstag ein Livestream statt.
  </div>


 -->


   


    <!--<button type="button" class="WhiteboardBtn" id="whiteboardToggle" data-toggle="modal" data-target=".bd-example-modal-lg">Whiteboard</button>-->

</body>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">


        <div id="whiteboard">
          <iframe src="https://wbo.ophir.dev/boards/wesdemowhiteboardcityhalltestumgebungnichtfinal#0,0,1.0" style="border:0px #ffffff none;" name="whiteboardFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="100%" width="100%" allowfullscreen>
          </iframe>
         </div>


    </div>
  </div>
</div>

<button class="toprightBtn" id="cityhallAgenda" (click)="timetableEinblenden()"><img src="assets/images/agenda-white.png" title="Detailprogramm" /></button>

<div class="countdownAgenda" id="timetableNeu">
  <p id="closeCityhallAgenda" (click)="timetableEinblenden()">X</p>
  <h2>Detailprogramm:</h2>
  <ul>
      <li>9:00 - 10:00 Uhr: Eröffnung und Begrüßung, Moderation: Prof. Dr. Kay-Uwe Götz (LfL)</li><ul>
        <li>Dr. Eric Veulliet
          Präsident der Hochschule Weihenstephan-Triesdorf (HSWT)</li>
        <li>Jakob Opperer
          Präsident der Bayerischen Landesanstalt für Landwirtschaft (LfL)</li>
          <li>Hubert Heigl
            Erster Vorsitzender der Landesvereinigung für ökologischen Landbau in Bayern (LVÖ)</li>
            <li>Keyword: Bernhard Schreyer - Betriebsporträt und Visionen für die Praxis-Forschung
              Schloss Gut Obbach, Sieger beim Bundespreis Ökologischer Landbau 2020</li>
      </ul>
      
      <li>10:00 - 12:15 Uhr: Vortragssektion am Vormittag (Pflanzenbau/Pflanzenzüchtung/Spezialkulturen/Ressourcenschutz), Moderation: Dr. Markus Demmel (LfL)</li><ul>
        <li>Anbauwürdigkeit von ausdauerndem Weizen in Deutschland
          Werner Vogt-Kaute, Öko-BeratungsGesellschaft mbH, Fachberatung für Naturland</li>
          <li>Pollensterilität bei der Echten Kamille - Wege der Zielerreichung in der ökologisch verträglichen Pflanzenzüchtung
            Dr. Bettina Faehnrich Veterinärmedizinische Universität Wien, AG Funktionelle Pflanzenstoffe; Universität für Bodenkultur, Institut für Pflanzenbau, Tulln, Österreich; Hochschule Weihenstephan-Triesdorf, Biomasse-Institut</li>
            <li>Auswirkung von entspelzt und im Spelz gesätem Dinkel
              Dr. Peer Urbatzka, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
              <li>Einfluss der Saatstärke auf Ertrag und Qualität verschiedener Wintergetreidearten
                Dr. Peer Urbatzka, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
                <li>Etablierung von Raubmilben in der Hopfenbau-Praxis über Untersaaten
                  Maria Obermaier, Bayerische Landesanstalt für Landwirtschaft, Institut für Pflanzenbau und Pflanzenzüchtung</li>
                  <li>Vergleich der Leistung von Soja und Erbse in Süddeutschland
                    Dr. Peer Urbatzka, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
                    <li>Optimierung der Anbautechnik bei der Weißen Lupine
                      Andrea Winterling, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
                      <li>Einfluss temporärer und dauerhafter pflugloser Bodenbearbeitung im ökologischen Landbau
                        Dr. Peer Urbatzka, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
                        <li>Erprobung einer GIS gestützten Methode zur Erosionsschutz-Beratung in Biobetrieben
                          Florian Ebertseder, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
      </ul>
      <li>12:15 - 13:30 Uhr: Mittagspause</li>
      <li>13:30 – 14:30 Uhr: Postersektion, Moderation: Prof. Dr. Wilhelm Pflanz (HSWT)</li><ul>
        <li>Bestimmung der scheinbaren Verdaulichkeit der Rohnährstoffe von Luzerne- und Rotkleeprodukten in der ökologischen Schweinefütterung
          Prof. Dr. Gerhard Bellof, Hochschule Weihenstephan-Triesdorf</li>
          <li>Einsatz von Luzernetrockenblatt in der ökologischen Legehennenfütterung
            Prof. Dr. Gerhard Bellof, Hochschule Weihenstephan-Triesdorf</li>
            <li>Fischteiche als Rückzugsraum für seltene Arten
              Dr. Jan Másílko, Bayerische Landesanstalt für Landwirtschaft, Institut für Fischerei</li>
              <li>Rispenhirse, eine trockenheitstolerante Kultur für die menschliche Ernährung und für die Geflügelfütterung
                Werner Vogt-Kaute, Öko-BeratungsGesellschaft mbH, Fachberatung für Naturland</li>
                <li>Regenwürmer profitieren im ökologischen Landbau von Kleegras als Vorfrucht und von Kleegrasmulch zur organischen Düngung
                  Roswitha Walter, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
                  <li>Marktpotentiale für bayerische Öko-Verarbeitungskartoffeln
                    Alexandra Mayr, Hochschule Weihenstephan-Triesdorf</li>
                    <li>Potentiale für Buchweizen, Quinoa, Amaranth und Hirse aus bayerischem ökologischen Anbau
                      Prof. Dr. Paul Michels, Hochschule Weihenstephan-Triesdorf</li>
                      <li>Sieben Jahre BioRegio Betriebsnetz Bayern – eine Zwischenbilanz
                        Dr. Klaus Wiesinger, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
      </ul>
      <li>14:30 - 14:45 Uhr: Pause</li>
      <li>14:45 – 16:30: Vortragssektionen am Nachmittag mit Schlusswort (Rinder/Kleinwiederkäuer/Grünland), Moderation: Prof. Dr. Hubert Spiekers (LfL)</li><ul>
        <li>Entwicklung eines digitalen Weideinformationssystems
          Dr. Jonas F. Weber, Landwirtschaftliches Zentrum für Rinderhaltung, Grünlandwirtschaft, Milchwirtschaft, Wild und Fischerei Baden-Württemberg</li>
          <li>Einfluss von organischer N-Düngung auf die Entwicklung von Leguminosen-Nachsaaten
            Dr. Karin Weggler, Landwirtschaftliches Zentrum Baden Württemberg, Fachbereich Grünlandwirtschaft und Futterbau</li>
            <li>Nutzungsintensität und Gülleeinsatz bei Grünland – Versuchsergebnisse aus Kringell
              Dr. Michael Diepolder, Bayerische Landesanstalt für Landwirtschaft, Institut für Ökologischen Landbau, Bodenkultur und Ressourcenschutz</li>
              <li>Funktionssicherheit integrierter Laufhöfe gemäß EG-Öko-VO in der Milchviehhaltung
                Jochen Simon, Bayerische Landesanstalt für Landwirtschaft, Institut für Landtechnik und Tierhaltung</li>
                <li>Untersuchungen zur Entwicklung der Zucht auf natürliche Hornlosigkeit bei Braunvieh und Fleckvieh in Bayern
                  Dieter Krogmeier, Bayerische Landesanstalt für Landwirtschaft, Institut für Tierzucht</li>
                  <li>Hörner im Laufstall – Herdenmanagement hat großen Einfluss
                    Ulrich Mück, Demeter Erzeugerring e.V.</li>
      </ul>
      <li>Schlusswort von LfL-Präsident Jakob Opperer</li>
  </ul>
  <div id="nextAgenda"></div>
</div>