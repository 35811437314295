<div id="openChat" class="unselectable" (mouseover)="showChat()">
    CHAT<br><<<span *ngIf="benAnzahl > 0" class="badge badge-pill badge-light" (click)="resetNot()" id="benAnzahl" >!</span>
</div>

<div id="chatfenster">
    <nav class="navbar sticky-top navbar-light bg-light justify-content-between">
        <a class="navbar-brand">  CHAT</a>

        <div class="form-inline">
          <div class="btn-group" role="group" aria-label="Basic example">
          <a id="publicBtn" class="btn btn-outline-secondary active" (click)="toggle('public')">Frage stellen <span *ngIf="benAnzahl > 0" class="badge badge-pill badge-light" (click)="resetNot()" id="benAnzahl" >!</span><span class="sr-only">(current)</span></a>
          <!--<a id="privateBtn" class="btn btn-outline-secondary" (click)="toggle('private')">Private Chats <span *ngIf="privBen === true" class="badge badge-pill badge-light" id="benAnzahl" >!</span><span class="sr-only"></span></a>-->
        </div>
            <button id="hideChat" (click)="hideChat()"><img src="assets/images/close.png" style="max-width: 3em; max-height: 3em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Schließen" /></button>
            
            
        </div>
      </nav>
      <div id="publicChatDiv"><app-questions  [chatroom]="101"></app-questions></div>
      <!--<div id="privateChatDiv" style="display: none;"><app-private-chat (privateChatEmit)="getMessagePrivate($event)"></app-private-chat></div>-->
            

</div>

<div id="ausgrauen" (click)="hideChat()"></div>