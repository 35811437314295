import { verifyHostBindings } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { DecodeTokenService } from '../services/decode-token.service';
import {VotingsService} from '../services/votings.service';
declare var $;

@Component({
  selector: 'app-cityhall-voting',
  templateUrl: './cityhall-voting.component.html',
  styleUrls: ['./cityhall-voting.component.css']
})
export class CityhallVotingComponent implements OnInit {
  data: any;
  results: any;
  votes: any;
  howManyVotes: any;
  constructor(private votingsService: VotingsService, private decodeTokenService: DecodeTokenService) { }

  ngOnInit(): void {
    this.votingsService.getVoting(1)
      .subscribe(data => this.data = data);
      this.votes = '15';
      this.refresh();
    //setInterval(() => {this.refresh();}, 2000);
    $('progress').tooltip('enable');
  }

  refresh(){
    try {
      this.votingsService.getResults(1)
      .subscribe(results => {this.results = results; this.ausgeben();});
      $('progress').tooltip('enable');
    } catch (error) {
      
    }

    
    console.log("ref");
   
  }

  vote(optionId){
   
    document.getElementById('inhalt').style.display = "none";
    document.getElementById('ergebnisse').style.display = 'block';
    setTimeout(() => {this.refresh();}, 1000);
    
  }

  ausgeben(){
    this.howManyVotes = 0;
    this.results.options.forEach(element => {
      console.log(element.results);
        this.howManyVotes = this.howManyVotes + element.results.length;
    });

    this.results.options.forEach(element => {
        let counter = Math.round(element.results.length/this.howManyVotes*100) ;
        console.log(counter.toString()+"%");
        var elem = document.getElementById("affe1");
        elem.style.width = "100%";
    });
    console.log(this.howManyVotes);
  }


  closeVote(){
    document.getElementById('abstimmung').style.display = "none";
  }
}
